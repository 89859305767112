<template>
  <div ref="tableParent"
       class="pb-13 pb-sm-0 px-sm-3 tableParent"
       style="height: 100%;
       overflow-y: auto;">
    <v-data-table :headers="headers"
                  :item-class="(item) => `pointer-hover row-label row-label-${item.type.id}`"
                  :items="filteredInspectionList"
                  :items-per-page="-1"
                  :loading="inspectionLoading"
                  class="elevation-2 ma-1 mt-2"
                  fixed-header
                  hide-default-footer
                  item-key="index"
                  loading-text="Loading... Please wait"
                  @click:row="openDialog">
      <template v-slot:top>
        <div class="d-flex flex-wrap justify-center justify-sm-space-between pa-2 pb-0"
             style="border-bottom: thin solid rgba(0, 0, 0, 0.12);">
          <v-select v-model="toggleMode"
                    :items="selectorItems"
                    block
                    class="mb-2"
                    dense
                    hide-details
                    item-text="name"
                    item-value="id"
                    outlined
                    style="max-width: 300px;">
            <template v-slot:item="{ item }">
              <div v-if="item.id" :class="'dot-type dot-type-'+item.id"></div>
              <div :class="{'mx-auto' : !item.id}">{{ item.name }}</div>
            </template>
          </v-select>
          <v-btn v-if="can_add_inspection"
                 id="addInspectionBtn"
                 class="d-none d-sm-inline mb-2"
                 color="green white--text"
                 elevation="0"
                 @click="$store.dispatch('inspectionDamageDialog/openNewInspection')">
            <v-icon>mdi-plus</v-icon>
            เพิ่มการตรวจสอบ
          </v-btn>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="item.status.id === 2 ? '#6bad66' : '#FFB800'"
                small
                text-color="white">
          {{ item.status.name }}
        </v-chip>
      </template>
      <template v-slot:item.date="{ item }">
        <span v-if="item.dateInspectionPeriod && item.dateInspectionPeriod.start">
          {{ dateToThai(stringToDate(item.dateInspectionPeriod.start)) }}
        </span>
        <span v-if="item.dateInspectionPeriod && item.dateInspectionPeriod.end">
          - {{ dateToThai(stringToDate(item.dateInspectionPeriod.end)) }}
        </span>
      </template>
      <template v-slot:item.damage="{ item }">
        <div>เสียหาย {{ item.countDamage }} จุด</div>
        <div v-if="item.countCritical" class="red--text font-weight-bold"> ({{ item.countCritical }} วิกฤต)</div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import utils from '@/assets/js/utils'

export default {
  mounted () {
    this.bridgeId = this.$route.query.id.trim()
    this.$store.dispatch('damageDetailSupply/fetchSurveyItem')
  },
  data: () => ({
    bridgeId: 0,
    toggleMode: 0,
    stringToDate: utils.stringToDate,
    dateToThai: utils.dateToThai,
    // --
    headers: [
      { text: 'การตรวจสอบ', align: 'left', sortable: false, value: 'type.name' },
      { text: 'สถานะ', align: 'center', sortable: false, value: 'status' },
      { text: 'วันที่', align: 'left', sortable: false, value: 'date' },
      { text: 'ความเสียหาย', align: 'left', sortable: false, value: 'damage', width: 120 }
    ],
    tableHeight: 300
  }),
  computed: {
    ...mapState('damageDetailSupply', { surveyTypeItem: 'surveyTypeItem' }),
    ...mapState('bridgeDetail', { inspectionList: 'inspectionList', inspectionLoading: 'inspectionLoading' }),
    ...mapGetters('bridgeDetail', { canAdd_permission: 'canAdd_permission' }),
    ...mapGetters({ user_can_add_inspection: 'can_add_inspection' }),
    can_add_inspection () {
      return this.user_can_add_inspection && this.canAdd_permission
    },
    selectorItems () {
      const ret = []
      const items = this.inspectionList.reduce((acc, cur) => {
        const typeId = cur.type.id
        const originalText = cur.type.name
        const target = acc[typeId] || { count: 0, text: originalText }
        target.count += 1
        acc[typeId] = target
        return acc
      }, {})
      Object.entries(items).forEach(([key, value]) => {
        ret.push({ id: parseInt(key), name: value.text + ` (${value.count})` })
      })
      ret.unshift({ id: 0, name: 'การตรวจสอบทั้งหมด' + ` (${this.inspectionList.length})` })
      return ret
    },
    /**
     * @returns {{ dateInspectionPeriod, status, type, countDamage, countCritical }[]}
     */
    filteredInspectionList () {
      return this.toggleMode === 0
        ? this.inspectionList
        : this.inspectionList.filter(item => String(item.type.id) === String(this.toggleMode))
    }
  },
  methods: {
    openDialog (item, vuetifyRowEvent) {
      this.$store.dispatch('inspectionDamageDialog/setInspectionDialog', { ...item, bridgeId: this.bridgeId })
    }
  },
  watch: {
    async bridgeId (to, from) {
      if (to !== from && to) {
        this.$store.commit('inspectionDamageDialog/SET_BRIDGE_ID', to)
        await this.$store.dispatch('bridgeDetail/getInspectionList', { id: this.bridgeId })
      }
    }
  }
}
</script>

<style scoped>
::v-deep .v-select.v-input--dense .v-select__selection--comma {
  margin: unset !important;
}

::v-deep .dot-type {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 8px;
}

::v-deep .dot-type-1 {
  background-color: #219EC9;
}

::v-deep .dot-type-2 {
  background-color: #5F9F50;
}

::v-deep .dot-type-3 {
  background-color: #F68C20;
}

::v-deep .dot-type-4 {
  background-color: #DA3F46;
}

@media screen and (min-width: 600px) {
  ::v-deep .v-data-table__wrapper {
    max-height: calc(100% - 72px);
  }
}

@media screen and (max-width: 600px) {
  .tableParent {
    overflow-y: auto;
  }

  .tableParent::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (min-width: 600px) {
  ::v-deep .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.875rem !important;
    color: black;
  }

  ::v-deep .row-label td:first-child {
    padding: 0 16px 0 12px;
  }

  ::v-deep .row-label-1 td:first-child {
    border-left: 4px solid #219EC9;
  }

  ::v-deep .row-label-2 td:first-child {
    border-left: 4px solid #5F9F50;
  }

  ::v-deep .row-label-3 td:first-child {
    border-left: 4px solid #F68C20;
  }

  ::v-deep .row-label-4 td:first-child {
    border-left: 4px solid #DA3F46;
  }
}

@media screen and (max-width: 600px) {
  ::v-deep .row-label-1 {
    border-left: 4px solid #219EC9;
  }

  ::v-deep .row-label-2 {
    border-left: 4px solid #5F9F50;
  }

  ::v-deep .row-label-3 {
    border-left: 4px solid #F68C20;
  }

  ::v-deep .row-label-4 {
    border-left: 4px solid #DA3F46;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tableParent",staticClass:"pb-13 pb-sm-0 px-sm-3 tableParent",staticStyle:{"height":"100%","overflow-y":"auto"}},[_c('v-data-table',{staticClass:"elevation-2 ma-1 mt-2",attrs:{"headers":_vm.headers,"item-class":function (item) { return ("pointer-hover row-label row-label-" + (item.type.id)); },"items":_vm.filteredInspectionList,"items-per-page":-1,"loading":_vm.inspectionLoading,"fixed-header":"","hide-default-footer":"","item-key":"index","loading-text":"Loading... Please wait"},on:{"click:row":_vm.openDialog},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap justify-center justify-sm-space-between pa-2 pb-0",staticStyle:{"border-bottom":"thin solid rgba(0, 0, 0, 0.12)"}},[_c('v-select',{staticClass:"mb-2",staticStyle:{"max-width":"300px"},attrs:{"items":_vm.selectorItems,"block":"","dense":"","hide-details":"","item-text":"name","item-value":"id","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(item.id)?_c('div',{class:'dot-type dot-type-'+item.id}):_vm._e(),_c('div',{class:{'mx-auto' : !item.id}},[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.toggleMode),callback:function ($$v) {_vm.toggleMode=$$v},expression:"toggleMode"}}),(_vm.can_add_inspection)?_c('v-btn',{staticClass:"d-none d-sm-inline mb-2",attrs:{"id":"addInspectionBtn","color":"green white--text","elevation":"0"},on:{"click":function($event){return _vm.$store.dispatch('inspectionDamageDialog/openNewInspection')}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" เพิ่มการตรวจสอบ ")],1):_vm._e()],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status.id === 2 ? '#6bad66' : '#FFB800',"small":"","text-color":"white"}},[_vm._v(" "+_vm._s(item.status.name)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.dateInspectionPeriod && item.dateInspectionPeriod.start)?_c('span',[_vm._v(" "+_vm._s(_vm.dateToThai(_vm.stringToDate(item.dateInspectionPeriod.start)))+" ")]):_vm._e(),(item.dateInspectionPeriod && item.dateInspectionPeriod.end)?_c('span',[_vm._v(" - "+_vm._s(_vm.dateToThai(_vm.stringToDate(item.dateInspectionPeriod.end)))+" ")]):_vm._e()]}},{key:"item.damage",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("เสียหาย "+_vm._s(item.countDamage)+" จุด")]),(item.countCritical)?_c('div',{staticClass:"red--text font-weight-bold"},[_vm._v(" ("+_vm._s(item.countCritical)+" วิกฤต)")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }